<template>
  <div>
    <div style="margin: 20px 0 0 20px">
      <MyBreadcrumb></MyBreadcrumb>
    </div>
    <div class="system">
      <div class="system-item" @click="goPage('/system/role')">
        <img src="../../assets/images/icon/user.png">
        <p>角色管理</p>
      </div>
      <div class="system-item" @click="goPage('/system/group')">
        <img src="../../assets/images/icon/hamburger_button.png">
        <p>用户组管理</p>
      </div>
      <div class="system-item" @click="goPage('/system/menu')">
        <img src="../../assets/images/icon/hamburger_button.png">
        <p>菜单权限管理</p>
      </div>
      <div class="system-item" @click="goPage('/system/permission')">
        <img src="../../assets/images/icon/api.png">
        <p>接口权限管理</p>
      </div>
      <div class="system-item" @click="goPage('/system/setting')">
        <img src="../../assets/images/icon/setting.png">
        <p>全局设置</p>
      </div>
      <div class="system-item" @click="goPage('/system/auth-refresh')">
        <img src="../../assets/images/icon/setting.png">
        <p>刷新权限</p>
      </div>
      <div class="system-item" @click="goPage('/system/refresh-cache')">
        <img src="../../assets/images/icon/setting.png">
        <p>刷新缓存</p>
      </div>
    </div>
    <div class="system">
      <div class="system-item" @click="goPage('/system/analysis')">
        <img src="../../assets/images/icon/analysis.png">
        <p>消息队列统计</p>
      </div>
      <div class="system-item" @click="goPage('/system/user-stat')">
        <img src="../../assets/images/icon/peoples-stat.png">
        <p>在线用户统计</p>
      </div>
      <div class="system-item" @click="goPage('/system/user-online-everyday')">
        <img src="../../assets/images/icon/peoples-stat.png">
        <p>每天在线用户统计</p>
      </div>
      <div class="system-item" @click="goPage('/system/ws-stat')">
        <img src="../../assets/images/icon/peoples-stat.png">
        <p>websocket用户统计</p>
      </div>
      <div class="system-item" @click="goPage('/system/match-robot')">
        <img src="../../assets/images/icon/peoples-stat.png">
        <p>竞赛机器人设置</p>
      </div>
    </div>
    <div class="system">
      <div class="system-item" @click="goPage('/system/ali-stat?week=-2')">
        <img src="../../assets/images/icon/community.png">
        <p>阿里云社区题库上上周统计</p>
      </div>
      <div class="system-item" @click="goPage('/system/ali-stat?week=-1')">
        <img src="../../assets/images/icon/community.png">
        <p>阿里云社区题库上周统计</p>
      </div>
      <div class="system-item" @click="goPage('/system/ali-stat')">
        <img src="../../assets/images/icon/community.png">
        <p>阿里云社区题库本周统计</p>
      </div>
      <!-- <div class="system-item" @click="goPage('/boss')">
        <img src="../../assets/images/icon/data-screen.png">
        <p>老版BOSS看板</p>
      </div> -->
    </div>
    <div class="system">
      <div class="system-item" @click="goPage('/system/chatgpt-list')">
        <img src="../../assets/images/icon/community.png">
        <p>chatgpt记录列表</p>
      </div>
      <!-- <div class="system-item" @click="goPage('/boss')">
        <img src="../../assets/images/icon/data-screen.png">
        <p>老版BOSS看板</p>
      </div> -->
    </div>
  </div>

</template>

<script>
export default {
  name: "index.vue",
  methods:{
    goPage(url){
      this.$router.push({
        path:url
      })
    },
  }
}
</script>

<style scoped lang="scss">
.system{
  margin: 20px;
  padding:20px;
  background: #FFFFFF;
  box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);
  border-radius: 4px;

  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  .system-item{
    margin-right: 20px;
    margin-bottom: 20px;
    padding: 0 20px;
    --width: 200px;
    height: 80px;
    border: 1px solid #dcdee2;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 4px;
    cursor: pointer;

    >img{
      width: 30px;
      height: 30px;
    }
    >p{
      font-size: 16px;
      font-weight: bold;
      margin-left: 10px;
    }
  }
}
</style>
